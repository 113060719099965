//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { accessoriesContent } from '~/components/products/content/accessories';

export default {
  name: 'InformationBlocks',
  components: {
    Faqs: () => import('~/components/checkout/components/Faqs.vue'),
    ObjectionKillers: () => import('~/components/checkout/components/ObjectionKillers.vue'),
    Upsell: () => import('~/components/checkout/components/Upsell.vue'),
  },
  props: {
    displayObjectionKillers: {
      type: Boolean,
      default: true,
    },
    displayFaqs: {
      type: Boolean,
      default: true,
    },
    displayUpsell: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters([
      'isShopifyOn',
      'isGQOrder',
    ]),
    upsellItem() {
      if (this.isShopifyOn && !this.isGQOrder) {
        const TEST_ID = 'gid://shopify/Product/6549519368281';
        const PROD_ID = 'gid://shopify/Product/8448257163500';

        return transformShopifyProdId({
          testShopifyId: TEST_ID,
          prodShopifyId: PROD_ID,
          oldId: 'fightcamp_quickwraps_3pack',
          store: this.$store,
        });
      }

      return accessoriesContent.find(item => item.id === 'fightcamp_quickwraps_3pack');
    },
  },
};
